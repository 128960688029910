header {
  padding-left: 0;
}

header.app-bar {
  color: #ffffff;
  background: #3a343d;
}

header .nav-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

header .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

header .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

header .nav-link svg {
  color: inherit;
}

.logo-block {
  width: 240px;
  text-align: center;
}

.logo-block img {
  height: 40px;
}
