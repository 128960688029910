table.contacts th {
    text-transform: uppercase;
    color: gray;
  }
  
  table.contacts a {
    color: #000;
  }
  
  table.contacts thead tr {
    background: #f3f3f3;
  }