table.tickets th {
  text-transform: uppercase;
  color: gray;
}

table.tickets a {
  color: #000;
}

table.tickets thead tr {
  background: #f3f3f3;
}