.chat-container {
    /* max-height: 500px; */
    overflow-y: auto;
    padding: 16px;
    margin-top: 16px;
    background-color: #f5f5f5;
  }
  
  .message {
    padding: 10px;
    margin-bottom: 12px;
    border-radius: 8px;
    word-wrap: break-word;
  }
  
  .customer-message {
    background-color: #e3f2fd;
    align-self: flex-start;
  }
  
  .bot-message {
    background-color: #ffecb3;
    align-self: center;
  }
  
  .support-message {
    background-color: #c8e6c9;
    align-self: flex-end;
  }
  
  .message-sender {
    font-weight: bold;
  }
  
  .message-time {
    font-size: 0.8rem;
    color: #616161;
    margin-bottom: 4px;
  }
  
  .message-body {
    white-space: pre-wrap;
  }
  