.satisfaction-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 30%;
}

.satisfaction-number {
  font-weight: bold;
  color: black;
}

.reports-progress-bar {
  margin-right: 8px;
}

h6.block-title {
  color: #545454;
  font-size: 14px;
  text-transform: uppercase;
}
